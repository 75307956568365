import logo from "./logo.webp"

const  clientData = {
   client_entity: 23,
   attorney_firm: 'borowitzclark.com',
    attorney_name: 'Erik Clark',
    attorney_number: '877-439-9717',
    attorney_email: 'Eclark@borowitzclark.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_borowitz_clark+(720p).mp4',
    mainColor: '#575D6C',
    secondaryColor: '#417A9F',
    siteLink: 'https://www.borowitzclark.com',
    logo
}

export default clientData